import React from 'react'
import SinglePageLayout from '../components/SinglePageLayout';
import SEO from '../components/seo';

function DownloadPage() {
    return (
        <SinglePageLayout>
            <SEO title="Toggol — Download Official App Installer" description="On this page you can download toggol desktop app for different platforms like Windows, macOS and Linux." />
            <div className="container-page">
                <div className="page-hero hero">
                    <h1 className="page-title">Download</h1>
                    <p className="page-desc">Toggol is available for all most popular platforms, here you can download an installer for Windows, macOS and Linux.</p>
                </div>
                <div className="page-content">
                    <div id="windows" className="anchor2"></div>
                    <h2 className="title-left-separator">Windows</h2>
                    <div className="my-4">
                        <span className="text-accent">Download:</span> <a href={`https://toggol.s3.amazonaws.com/downloads/win/toggol-installer-windows-exe-64.exe`} download className="btn btn-md btn-accent mx-2 btn-download btn-download-win-64">64-bit</a><a href={`https://toggol.s3.amazonaws.com/downloads/win/toggol-installer-windows-exe-32.exe`} download className="btn btn-md btn-accent mx-2 btn-download btn-download-win-32">32-bit</a>
                    </div>
                    <div className="my-4">
                        <span className="my-1">Instalation instructions:</span>
                        <ol className="list">
                            <li>Download the installation file and launch it.</li>
                            <li>Keep pressing <span className="btn btn-sm none-hover">Continue</span> as the installation wizard advises until you are done with the installation.</li>
                            <li>Done! Now you can launch and use it.</li>
                        </ol>
                        <p>If the installation file or app itself doesn't launch, try to right-click it and <span className="btn btn-sm none-hover">Open as administrator</span></p>
                    </div>
                    <div id="mac" className="anchor2"></div>
                    <h2 className="title-left-separator">macOS</h2>
                    <div className="my-2">
                        <span className="text-accent">Download:</span> <a href={`https://toggol.s3.amazonaws.com/downloads/mac/toggol-installer-mac-dmg.dmg`} download className="btn btn-accent btn-md mx-1 btn-download btn-download-mac-dmg">*.dmg</a>
                    </div>
                    <div className="my-4">
                        <span className="my-1">Instalation instructions:</span>
                        <ol className="list">
                            <li>Download the installation file and launch it.</li>
                            <li>Drag and drop the Toggol App icon to the Application folder as the installation wizard advice.</li>
                            <li><strong className="text-accent">IMPORTANT!</strong> In order to use the Toggol App you should allow it in System Preferences. Open <span className="btn btn-sm none-hover">System Preferences</span> &gt; <span className="btn btn-sm none-hover">Security & Privacy</span> &gt; Click the lock <span className="btn btn-sm none-hover">🔒</span> icon to unlock it &gt; Press <span className="btn btn-sm none-hover">Allow Toggol</span> in right bottom corner of the window.</li>
                            <li>Done! Now you can launch and use it.</li>
                        </ol>
                    </div>
                    <div id="linux" className="anchor2"></div>
                    <h2 className="title-left-separator">Linux</h2>
                    <div className="my-2">
                        <span className="text-accent">Download:</span> <a href={`https://toggol.s3.amazonaws.com/downloads/linux/toggol-installer-linux-deb.deb`} download className="btn btn-accent btn-md mx-2 btn-download btn-download-linux-deb">*.deb</a><a href={`https://toggol.s3.amazonaws.com/downloads/linux/toggol-installer-linux-tar.tar.xz`} download className="btn btn-accent btn-md mx-2 btn-download btn-download-linux-tar-xz">*.tar.xz</a>
                    </div>
                    <div className="my-4">
                        <span className="my-1">Instalation instructions:</span>
                        <ol className="list">
                            <li>Download the installation file and launch it.</li>
                            <li>Keep press <span className="btn btn-sm none-hover">Continue</span> as the installation wizard advises until you are done with the installation.</li>
                            <li>Done! Now you can launch and use it.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </SinglePageLayout>
    )
}

export default DownloadPage
